import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  pnSalepageUrl,
  pnSalepageFileUrl,
} from "../../utils/baseUrl";

import axios from "axios";

import Swal from "sweetalert2";

const EditSalepage = () => {
  const { id } = useParams();

  const [lineurl, setLineurl] = useState("");
  const [fburl, setFburl] = useState("");
  const [phone, setPhone] = useState("");
  const [weburl, setWeburl] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [selectedTwFile, setSectedTwFile] = useState(null);
  const [selectedTrFile, setSectedTrFile] = useState(null);
  const [incomes, setIncomes] = useState({});
  const [datas, setDatas] = useState({});
  const [userId, setUserId] = useState("");
  // const { id } = useParams();
  //getsalepagebyid.php

  const handleOnChange = (e) => {
    /*     console.log("----------");
            console.log(e.target.files[0]);
            console.log("----------"); */
    setSectedFile(e.target.files[0]);
  };

  const handleTwOnChange = (e) => {
    /*     console.log("----------");
            console.log(e.target.files[0]);
            console.log("----------"); */
    setSectedTwFile(e.target.files[0]);
  };
  const handleTrOnChange = (e) => {
    /*     console.log("----------");
            console.log(e.target.files[0]);
            console.log("----------"); */
    setSectedTrFile(e.target.files[0]);
  };

  function readDatas(id) {
    axios
      .get(`${pnSalepageUrl}getsalepagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setFburl(response.data?.fburl);
        setWeburl(response.data?.weburl);
        setPhone(response.data?.phone);
        setLineurl(response.data?.lineurl);
      });
  }
  useEffect(() => {
    var user = localStorage.getItem("id");
    console.log("user: ", user);
    setUserId(user);
    readDatas(id);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    //updatesalepage.php
    //updatesalepage.php
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("fileTwData", selectedTwFile);
    formData.append("fileTrData", selectedTrFile);
    formData.append("lineurl", lineurl);
    formData.append("phone", phone);
    formData.append("pnId", userId);
    formData.append("fburl", fburl);
    formData.append("weburl", weburl);
    formData.append("idEdit", id); //idEdit: id,
    axios
      .post(`${pnSalepageUrl}updatesalepage.php`, formData)
      .then(function (response) {
        console.log(response.data);
        setSectedFile(null);
        setSectedTwFile(null);
        setSectedTrFile(null);
        readDatas(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
  };

  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-6">
                <label>Line ID-{id}</label>
                <input
                  type="text"
                  value={lineurl}
                  onChange={(e) => setLineurl(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>

              <div className="mb-6">
                <label>Facebook</label>
                <input
                  type="url"
                  value={fburl}
                  onChange={(e) => setFburl(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>
            <div className="mb-6">
              <label>Phone</label>
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-6">
                <label>Website</label>
                <input
                  type="url"
                  value={weburl}
                  onChange={(e) => setWeburl(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>
            <div className="mb-4">
              {datas.picture != "" ? (
                <img
                  src={`${pnSalepageFileUrl}${datas.picture}`}
                  className="rounded-lg mb-0 w-[200px] h-[100px] object-cover mb-2"
                />
              ) : (
                ""
              )}
              <label>Image 1</label>
              <input type="file" onChange={handleOnChange} />
            </div>
            <div className="mb-4">
              {datas.picture2 != "" ? (
                <img
                  src={`${pnSalepageFileUrl}${datas.picture2}`}
                  className="rounded-lg mb-0 w-[200px] h-[100px] object-cover mb-2"
                />
              ) : (
                ""
              )}
              <label>Image 2</label>
              <input type="file" onChange={handleTwOnChange} />
            </div>
            <div className="mb-4">
              {datas.picture3 != "" ? (
                <img
                  src={`${pnSalepageFileUrl}${datas.picture3}`}
                  className="rounded-lg mb-0 w-[200px] h-[100px] object-cover mb-2"
                />
              ) : (
                ""
              )}
              <label>Image 3</label>
              <input type="file" onChange={handleTrOnChange} />
            </div>
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditSalepage;
