import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";

import {
  Home,
  SignIn,
  Policy,
  ChangePwd,
  AccountDistribute,
  Report,
  BankAccount,
  AdvertUnit,
  SalePage,
} from "./pages";
import {
  AddDistribute,
  EditDistribute,
  ViewDistribute,
  BankDistributeAccount,
  ViewMember,
  ViewOrder,
} from "./components/distribute";

import { AddUnit, EditUnit, ViewUnit } from "./components/advert";
import {
  AddSalepage,
  ViewSalepage,
  PreviewSalepage,
  EditSalepage,
} from "./components/salepage";
const Layout = () => {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/acdistribute",
        element: <AccountDistribute />,
        children: [
          {
            path: "",
            element: <ViewDistribute />,
          },
          {
            path: "addacdistribute",
            element: <AddDistribute />,
          },
          {
            path: "editacdistribute/:id",
            element: <EditDistribute />,
          },
          {
            path: "bankacdistribute/:id",
            element: <BankDistributeAccount />,
          },
          {
            path: "viewmembers/:id",
            element: <ViewMember />,
          },
          {
            path: "viewordermember/:id",
            element: <ViewOrder />,
          },
        ],
      },
      {
        path: "/report",
        element: <Report />,
      },
      {
        path: "/salepage",
        element: <SalePage />, //AddSalepage, ViewSalepage, PreviewSalepage
        children: [
          {
            path: "",
            element: <ViewSalepage />,
          },
          {
            path: "addsalepage",
            element: <AddSalepage />,
          },
          {
            path: "editsalepage/:id",
            element: <EditSalepage />,
          },
          {
            path: "previewsalepage/:id",
            element: <PreviewSalepage />,
          },
        ],
      },
      {
        path: "/advertunit",
        element: <AdvertUnit />, //AddUnit, EditUnit, ViewUnit
        children: [
          {
            path: "",
            element: <ViewUnit />,
          },
          {
            path: "addunit",
            element: <AddUnit />,
          },
          {
            path: "editunit/:id",
            element: <EditUnit />,
          },
        ],
      },
      {
        path: "/changepwd",
        element: <ChangePwd />,
      },
      {
        path: "/bankaccount",
        element: <BankAccount />,
      },
    ],
  },
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "/policy",
    element: <Policy />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
