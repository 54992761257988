import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  pnSalepageUrl,
} from "../../utils/baseUrl";

import axios from "axios";

import Swal from "sweetalert2";

const AddSalepage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "0",
      disId: " 0",
      salepageId: "0",
      lineurl: "",
      fburl: "",
      phone: "",
      weburl: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [selectedTwFile, setSectedTwFile] = useState(null);
  const [selectedTrFile, setSectedTrFile] = useState(null);
  const [incomes, setIncomes] = useState({});
  const [datas, setDatas] = useState({});
  const [userId, setUserId] = useState("");
  // const { id } = useParams();

  useEffect(() => {
    var user = localStorage.getItem("id");
    console.log("user: ", user);
    setUserId(user);
    // readData(id);
  }, []);

  /*   useEffect(() => {
           
          }, []); */
  const handleOnChange = (e) => {
    /*     console.log("----------");
            console.log(e.target.files[0]);
            console.log("----------"); */
    setSectedFile(e.target.files[0]);
  };

  const handleTwOnChange = (e) => {
    /*     console.log("----------");
            console.log(e.target.files[0]);
            console.log("----------"); */
    setSectedTwFile(e.target.files[0]);
  };
  const handleTrOnChange = (e) => {
    /*     console.log("----------");
            console.log(e.target.files[0]);
            console.log("----------"); */
    setSectedTrFile(e.target.files[0]);
  };

  function readData(id) {
    axios
      .get(`${pnSalepageUrl}readbyid.php/${id}/edit`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
      });
  }

  const handleOnSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("fileTwData", selectedTwFile);
    formData.append("fileTrData", selectedTrFile);
    formData.append("lineurl", data.lineurl);
    formData.append("phone", data.phone);
    formData.append("pnId", userId);
    formData.append("fburl", data.fburl);
    formData.append("weburl", data.weburl);
    formData.append("salepageId", "0");
    axios
      .post(`${pnSalepageUrl}savesalepage.php`, formData)
      .then(function (response) {
        console.log(response.data);
        reset();
        setSectedFile(null);
        setSectedTwFile(null);
        setSectedTrFile(null);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
  };
  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="mb-4">
              <label>Line ID</label>
              <input
                type="text"
                name="lineurl"
                {...register("lineurl", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.lineurl && (
                <p className="text-red-500 text-xs italic mt-1">
                  Enter Line ID
                </p>
              )}
            </div>
            <div className="mb-4">
              <label>Facebook</label>
              <input
                type="url"
                name="fburl"
                {...register("fburl", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.fburl && (
                <p className="text-red-500 text-xs italic mt-1">
                  Enter Facebook
                </p>
              )}
            </div>
            <div className="mb-4">
              <label>Website</label>
              <input
                type="url"
                name="weburl"
                {...register("weburl", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.weburl && (
                <p className="text-red-500 text-xs italic mt-1">
                  Enter Website
                </p>
              )}
            </div>

            <div className="mb-4">
              <label>Phone Number</label>
              <input
                type="tel"
                name="phone"
                {...register("phone", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.phone && (
                <p className="text-red-500 text-xs italic mt-1">
                  Enter Phone Number
                </p>
              )}
            </div>
            <div className="mb-4">
              <label>Image 1</label>
              <input type="file" onChange={handleOnChange} />
            </div>
            <div className="mb-4">
              <label>Image 2</label>
              <input type="file" onChange={handleTwOnChange} />
            </div>
            <div className="mb-4">
              <label>Image 3</label>
              <input type="file" onChange={handleTrOnChange} />
            </div>
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSalepage;
