import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  bankUrl,
} from "../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const BankAccount = () => {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [userId, setUserId] = useState("");
  //   const [partnerID, setPartnerID] = useState("");
  const [bankname, setBankname] = useState("");
  const [bankno, setBankno] = useState("");
  const [bankbranch, setBankbranch] = useState("");
  const [bankacname, setBankAcname] = useState("");
  const [partnerData, setPartnerData] = useState({});
  const [bankData, setBankData] = useState([]);
  /*     bankname,bankno,
        bankbranch,bankacname, */
  function readBankeData() {
    axios.get(`${bankUrl}read.php`).then(function (response) {
      console.log(response.data);
      setBankData(response.data);
    });
  }

  function readData(id) {
    axios.get(`${partnerUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setBankname(
        response.data?.bankname != null ? response.data?.bankname : ""
      );
      setBankno(response.data?.bankno != null ? response.data?.bankno : "");
      setBankbranch(
        response.data?.bankbranch != null ? response.data?.bankbranch : ""
      );
      setBankAcname(
        response.data?.bankacname != null ? response.data?.bankacname : ""
      );
      /*     bankname,bankno,
        bankbranch,bankacname, */
    });
  }

  useEffect(() => {
    var user = localStorage.getItem("id");
    // var partner = localStorage.getItem("partnerID");
    if (user != null) {
      setUserId(user);
      //   setPartnerID(partner);
    }
    readBankeData();
    readData(user);
  }, []);
  //accounttype id,typename,description,flag,created_at

  /*   useEffect(() => {
      readData(userId);
      readBankeData();
    }, []); */

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${partnerUrl}updatebank.php`, {
        idEdit: userId,
        bankname,
        bankno,
        bankbranch,
        bankacname,
      })
      .then(function (response) {
        console.log(response.data);
        readData(userId);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            กำหนดบัญชีธนาคาร
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              {/*               <div className="mb-6">
            <label>Bank Name</label>
            <input
              type="text"
              value={bankname}
              onChange={(e) => setBankname(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div> */}
              <div className="mb-6">
                <label>ชื่อธนาคาร</label>
                <select
                  value={bankname}
                  onChange={(e) => setBankname(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                >
                  <option value=""></option>
                  {bankData.map((item, index) => (
                    <option key={index} value={item.thName}>
                      {item.thName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-6">
                <label>เลขบัญชี</label>
                <input
                  type="text"
                  value={bankno}
                  onChange={(e) => setBankno(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>

            <div className="mb-6">
              <label>สาขา</label>
              <input
                type="text"
                value={bankbranch}
                onChange={(e) => setBankbranch(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-6">
              <label>ชื่อบัญชี</label>
              <input
                type="text"
                value={bankacname}
                onChange={(e) => setBankAcname(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>

            <div className="flex justify-end mb-4">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {/* <FiSave size={22} /> */}
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BankAccount;
