import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  uUrl,
  imgGovUrl,
  imgPvUrl,
  imgUnUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const AddUnit = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      scope: "",
      url: "",
      agency: "",
      phone: "",
      mypackage: "",
    },
  });
  //govadvert id,photo,scope,url,agency,status
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [locations, setLocations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [userId, setUserId] = useState("");
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  function readLocation() {
    axios.get(`${uUrl}location.php`).then(function (response) {
      console.log(response.data);
      setLocations(response.data);
    });
  }
  function readPackage() {
    axios.get(`${uUrl}readpackage.php`).then(function (response) {
      console.log(response.data);
      setPackages(response.data);
    });
  }
  useEffect(() => {
    var id = localStorage.getItem("id");
    setUserId(id);
    readLocation();
    readPackage();
  }, []);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };
  //updateadvertcompany.php
  //deleteadvertcompany.php
  //addadvertcompany.php
  //readadvertcompany.php
  //readadvertcompanywithid.php;
  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("scope", data.scope);
    formData.append("url", data.url);
    formData.append("agency", data.agency);
    formData.append("phone", data.phone);
    formData.append("userID", userId);
    formData.append("packageID", data.mypackage);
    setLoading(true);
    //govadvert id,photo,scope,url,agency,status
    axios
      .post(`${uUrl}addadvertcompany.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
        //locations
      });
  };
  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="mb-2">
              <label>Scope</label>
              <select
                {...register("scope", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                <option value=""></option>
                <option value="0">All</option>
                {locations.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.thName}
                    {item.enName ? `(${item.enName})` : ""}-{item.id}
                  </option>
                ))}
              </select>
              {errors.scope && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Select Scope
                </div>
              )}
            </div>
            <div className="mb-2">
              <label>Package</label>
              <select
                {...register("mypackage", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                <option value=""></option>
                {/* <option value="0">All</option> */}
                {packages.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.title}/({item.price})
                  </option>
                ))}
              </select>
              {errors.mypackage && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Select Package
                </div>
              )}
            </div>
            {/*             advertpackage id,packageTypeID,title,shortdetail,longdetail,price,
,period,unit,photo,created_at */}
            <div className="mb-2">
              <label>Url Web site</label>
              <input
                type="url"
                name="url"
                {...register("url", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.url && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter Url Web site
                </div>
              )}
            </div>
            <div className="mb-2">
              <label>Email ลูกค้า</label>
              <input
                type="email"
                name="agency"
                {...register("agency", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.agency && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter Email ลูกค้า
                </div>
              )}
            </div>
            <div className="mb-4">
              <label>Phone Number</label>
              <input
                type="tel"
                name="phone"
                {...register("phone", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.phone && (
                <p className="text-red-500 text-xs italic mt-1">
                  Enter Phone Number
                </p>
              )}
            </div>
            <div className="my-2">
              <label>Photo</label>
              <div className="flex flex-col items-start">
                <input type="file" onChange={handleOnChange} />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddUnit;
