import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  buyPackageUrl,
} from "../../utils/baseUrl";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
  HiOutlineShoppingBag,
} from "react-icons/hi2";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import moment from "moment/min/moment-with-locales";
moment.locale("th");

const ViewOrder = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [userId, setUserId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 40;
  const pagesVisited = pageNumber * usersPerPage;
  //partnerID
  //readorderbydistribute.php
  function readOrder(pid, did) {
    axios
      .get(`${buyPackageUrl}readorderbydistributepartner.php/${pid}/${did}`)
      .then(function (response) {
        console.log(response.data);
        setOrderData(response.data);
      });
  }
  useEffect(() => {
    /*     var user = localStorage.getItem("id");
          console.log("user: ", user); */
    var user = localStorage.getItem("id");
    if (user != null) {
      setUserId(user);
    }
    readOrder(user, id);
  }, []);

  const pageCount = Math.ceil(orderData.length / usersPerPage);
  const displayAllDatas = orderData
    .slice(pagesVisited, pagesVisited + usersPerPage)
    .map((item, index) => {
      return (
        <tr key={index}>
          <td className="border-b border-[#eee] dark:border-strokedark">
            <h5 className="font-medium text-black dark:text-white">
              {moment(item?.orderDate).format("L")}
            </h5>
            {/*                       <p className="text-sm"> {item?.address}</p>
      <p className="text-sm"> {item?.phone}</p> */}
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            {item?.packageName}
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            {item?.packagePrice}
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            <h5
              className={` ${
                item?.paymentStatus == 2 ? " text-success " : " text-danger "
              } font-medium dark:text-white`}
            >
              {item?.paymentStatus === 2 ? "ชำระเงินแล้ว" : "รอตรวจสอบ"}
            </h5>
          </td>
        </tr>
      );
    });
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  /*   order_package id,memberID,partnerID,distributeID,orderDate,packageId,packageName,packagePrice,slipImg,
,paymentMethod,paymentStatus,created_at */
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการซื้อแพ็คเก็จ
          </h4>
          <div className="max-w-full overflow-x-auto">
            {/* ------ */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[200px] w-full font-medium text-black dark:text-white">
                    วันที่ซื้อแพ็คเก็จ
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    แพ็คเก็จ
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    ราคา
                  </th>
                  <th className="min-w-[150px] font-medium text-black dark:text-white">
                    สถานะชำระเงิน
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* ---------  */}
                {displayAllDatas}

                {/* ---------  */}
              </tbody>
            </table>
            <div className="my-8">
              <ReactPaginate
                previousLabel={"ก่อนหน้า"}
                nextLabel={"ถัดไป"}
                pageCount={pageCount}
                pageRangeDisplayed={4}
                marginPagesDisplayed={2}
                onPageChange={changePage}
                containerClassName={
                  "flex items-center justify-center space-x-1"
                }
                pageClassName={
                  "px-4 py-2 rounded-full bg-gray text-gray-700 bg-gray-200 rounded-md hover:bg-graydark hover:text-white"
                }
                previousLinkClassName={
                  "flex items-center px-4 py-2 text-gray-500 bg-gray-300 rounded-md"
                }
                nextLinkClassName={
                  "px-4 py-2 font-bold text-gray-500 bg-gray-300 rounded-md hover:bg-graydark hover:text-white"
                }
                breakLabel="..."
                breakClassName="page-item"
                activeClassName=""
                /*                 pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                disabledClassName={""} */
                // activeClassName="active"
                // activeClassName=""
                renderOnZeroPageCount={null}
              />
            </div>
            {/* ------ */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOrder;
