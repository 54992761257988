import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  CardOne,
  CardTwo,
  CardThree,
  CardFour,
  ChartOne,
  ChartTwo,
} from "../components";

//reportUrl
import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  buyPackageUrl,
  reportUrl,
} from "../utils/baseUrl";

import axios from "axios";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [countDistrbuteData, setCountDistrbuteData] = useState("0");
  const [countMemberData, setCountMemberData] = useState("0");
  const [countPackageData, setCountPackageData] = useState("0");
  const [userId, setUserId] = useState("");

  function readCountDistribute(id) {
    axios
      .get(`${reportUrl}countdistribute.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setCountDistrbuteData(response.data);
      });
  }
  function readCountMember(id) {
    axios.get(`${reportUrl}countmember.php/${id}`).then(function (response) {
      console.log(response.data);
      setCountMemberData(response.data);
    });
  }
  function readCountPackage(id) {
    axios
      .get(`${reportUrl}countorderpackage.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setCountPackageData(response.data);
      });
  }
  useEffect(() => {
    var user = localStorage.getItem("id");
    if (user != null) {
      setUserId(user);
    }
    readCountDistribute(user);
    readCountMember(user);
    readCountPackage(user);
  }, []);
  console.log("C DIS: ", countDistrbuteData);
  console.log("C MEM: ", countMemberData);
  console.log("C PC: ", countPackageData);
  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardOne data={countPackageData} />
        <CardTwo data={countPackageData} />
        <CardThree data={countDistrbuteData} />
        <CardFour data={countMemberData} />
      </div>
      <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <ChartOne />
        <ChartTwo />
        {/*<ChartThree />
        <MapOne /> */}
      </div>
    </>
  );
};

export default Home;
